<script>
  export let fill = "#999999";
</script>

<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="-302 395 7 4"
  style="enable-background:new -302 395 7 4;"
  xml:space="preserve"
>
  <path
    style="fill: {fill};"
    d="M-298.5,399L-298.5,399l-3.5-3.5V395h0.5l3,3l3-3h0.5v0.5L-298.5,399z"
  />
</svg>
