<div
  class="sticky bottom-0 z-40 flex h-10 w-full items-center justify-center border-t border-red-900 bg-red-800"
>
  <div class="flex flex-col">
    <span class="text-base font-light text-white">
      This toolkit is a testing ground for Rain proof of concepts and comes with
      no guarantees. Treat as alpha and use at your own risk!
    </span>
  </div>
</div>
