import { isAddress } from "ethers/lib/utils";
export const addressValidate = (value) => {
    if (value == "") {
        return { error: "Can't be blank" };
    }
    if (!isAddress(value)) {
        return { error: "Not a valid Ethereum address" };
    }
    return true;
};
export const defaultValidator = () => {
    return true;
};
