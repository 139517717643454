import { ethers } from "ethers";
;
import { parseUnits } from "ethers/lib/utils";
import { Sale, FixedPrice, vLBP, IncreasingPrice, SaleDurationInTimestamp } from 'rain-sdk';
export var selectSale;
(function (selectSale) {
    selectSale[selectSale["fixedPrice"] = 0] = "fixedPrice";
    selectSale[selectSale["vLBP"] = 1] = "vLBP";
    selectSale[selectSale["increasingPrice"] = 2] = "increasingPrice";
})(selectSale || (selectSale = {}));
;
export const getAfterTimestampDate = (stateConfig, i) => {
    if (stateConfig.sources[0] ===
        "0x0100" ||
        "0x060001001f00" ||
        "0x010107001d00060001001f0001021c00" ||
        "0x060001001f002f0001021e002002060001011f002102" ||
        "0x010307001d00060001001f002f0001021e002002060001011f00210201041c00" ||
        "0x060001001f0001012f001e002102" ||
        "0x010207001d00060001001f0001012f001e00210201031c00") {
        return new Date(parseInt(stateConfig.constants[i]) * 1000);
    }
};
export const getAfterTimestamp = (stateConfig, i) => {
    if (stateConfig.sources[0] ===
        "0x0100" ||
        "0x060001001f00" ||
        "0x010107001d00060001001f0001021c00" ||
        "0x060001001f002f0001021e002002060001011f002102" ||
        "0x010307001d00060001001f002f0001021e002002060001011f00210201041c00" ||
        "0x060001001f0001012f001e002102" ||
        "0x010207001d00060001001f0001012f001e00210201031c00") {
        return parseInt(stateConfig.constants[i]);
    }
};
export const saleStatuses = ["Pending", "Active", "Success", "Fail"];
export function canEndConfig(config, deployerAddress) {
    const _saleTime_ = new SaleDurationInTimestamp(config.inputValues.endTimestamp);
    if (config.extraTimeDiscountMode) {
        _saleTime_.applyExtraTime(config.inputValues.extraTime, config.inputValues.extraTimeAmount, config.inputValues.reserveErc20?.erc20decimals);
        if (config.creatorControlMode) {
            _saleTime_.applyOwnership(deployerAddress);
        }
    }
    else if (config.afterMinimumRaiseMode) {
        _saleTime_.afterMinimumRaise(config.inputValues.minimumRaise, config.inputValues.reserveErc20?.erc20decimals);
        if (config.creatorControlMode) {
            _saleTime_.applyOwnership(deployerAddress);
        }
    }
    else {
        if (config.creatorControlMode && config.canEndMode) {
            _saleTime_.applyExtraTime(config.inputValues.extraTime, config.inputValues.extraTimeAmount, config.inputValues.reserveErc20?.erc20decimals)
                .applyOwnership(deployerAddress);
        }
        if (!config.creatorControlMode && config.canEndMode) {
            _saleTime_.applyExtraTime(config.inputValues.extraTime, config.inputValues.extraTimeAmount, config.inputValues.reserveErc20?.erc20decimals);
        }
        if (config.creatorControlMode && !config.canEndMode) {
            _saleTime_.applyOwnership(deployerAddress);
        }
    }
    return _saleTime_;
}
export function calculatePriceConfig(config) {
    const saleSelector = () => {
        //if sale is a Fixed Price
        if (config.saleType == selectSale.fixedPrice) {
            return new FixedPrice(config.inputValues.startPrice, config.inputValues.reserveErc20?.erc20decimals);
        }
        //if sale is a vLBP
        if (config.saleType == selectSale.vLBP) {
            return new vLBP(config.inputValues.startPrice, config.inputValues.startTimestamp, config.inputValues.endTimestamp, config.inputValues.minimumRaise, config.inputValues.initialSupply, config.inputValues.reserveErc20?.erc20decimals);
        }
        //if sale is a Linear Increasing Price
        if (config.saleType == selectSale.increasingPrice) {
            return new IncreasingPrice(config.inputValues.startPrice, config.inputValues.endPrice, config.inputValues.startTimestamp, config.inputValues.endTimestamp, config.inputValues.reserveErc20?.erc20decimals);
        }
    };
    //creating the sale PriceCurve instance
    const _sale_ = saleSelector();
    //if extra time discount is enabled
    if (config.extraTimeDiscountMode) {
        _sale_.applyExtraTimeDiscount(config.inputValues.endTimestamp, config.inputValues.extraTimeDiscountThreshold, config.inputValues.extraTimeDiscount);
    }
    //if tier Discount is enabled
    if (config.tierDiscountMode) {
        //if tierActivation is enabled
        if (config.tierDiscountActMode) {
            _sale_.applyTierDiscount(config.inputValues.tierDiscountAddress, [
                config.inputValues.discountTier1,
                config.inputValues.discountTier2,
                config.inputValues.discountTier3,
                config.inputValues.discountTier4,
                config.inputValues.discountTier5,
                config.inputValues.discountTier6,
                config.inputValues.discountTier7,
                config.inputValues.discountTier8
            ], [
                config.inputValues.discountActTier1,
                config.inputValues.discountActTier2,
                config.inputValues.discountActTier3,
                config.inputValues.discountActTier4,
                config.inputValues.discountActTier5,
                config.inputValues.discountActTier6,
                config.inputValues.discountActTier7,
                config.inputValues.discountActTier8
            ]);
        }
        else {
            _sale_.applyTierDiscount(config.inputValues.tierDiscountAddress, [
                config.inputValues.discountTier1,
                config.inputValues.discountTier2,
                config.inputValues.discountTier3,
                config.inputValues.discountTier4,
                config.inputValues.discountTier5,
                config.inputValues.discountTier6,
                config.inputValues.discountTier7,
                config.inputValues.discountTier8
            ]);
        }
    }
    //if both Min and Max Cap Per Wallet are enabled
    if (config.maxCapMode && config.minCapMode) {
        //if Max cap tier Multiplier is enabled
        if (config.tierCapMulMode) {
            //if tierActivation is enabled
            if (config.tierCapMulActMode) {
                _sale_.applyWalletCap(2, {
                    maxWalletCap: config.inputValues.maxWalletCap,
                    minWalletCap: config.inputValues.minWalletCap,
                    tierMultiplierMode: true,
                    tierAddress: config.inputValues.tierCapMulAddress,
                    tierMultiplier: [
                        config.inputValues.capMulTier1,
                        config.inputValues.capMulTier2,
                        config.inputValues.capMulTier3,
                        config.inputValues.capMulTier4,
                        config.inputValues.capMulTier5,
                        config.inputValues.capMulTier6,
                        config.inputValues.capMulTier7,
                        config.inputValues.capMulTier8
                    ],
                    tierActivation: [
                        config.inputValues.capMulActTier1,
                        config.inputValues.capMulActTier2,
                        config.inputValues.capMulActTier3,
                        config.inputValues.capMulActTier4,
                        config.inputValues.capMulActTier5,
                        config.inputValues.capMulActTier6,
                        config.inputValues.capMulActTier7,
                        config.inputValues.capMulActTier8
                    ]
                });
            }
            else {
                _sale_.applyWalletCap(2, {
                    maxWalletCap: config.inputValues.maxWalletCap,
                    minWalletCap: config.inputValues.minWalletCap,
                    tierMultiplierMode: true,
                    tierAddress: config.inputValues.tierCapMulAddress,
                    tierMultiplier: [
                        config.inputValues.capMulTier1,
                        config.inputValues.capMulTier2,
                        config.inputValues.capMulTier3,
                        config.inputValues.capMulTier4,
                        config.inputValues.capMulTier5,
                        config.inputValues.capMulTier6,
                        config.inputValues.capMulTier7,
                        config.inputValues.capMulTier8
                    ]
                });
            }
        }
        else {
            _sale_.applyWalletCap(2, {
                maxWalletCap: config.inputValues.maxWalletCap,
                minWalletCap: config.inputValues.minWalletCap,
            });
        }
    }
    //if only Max cap per wallet is enabled
    if (config.maxCapMode && !config.minCapMode) {
        //if tier Multiplier is enabled
        if (config.tierCapMulMode) {
            //if tierActivation is enabled
            if (config.tierCapMulActMode) {
                _sale_.applyWalletCap(1, {
                    maxWalletCap: config.inputValues.maxWalletCap,
                    tierMultiplierMode: true,
                    tierAddress: config.inputValues.tierCapMulAddress,
                    tierMultiplier: [
                        config.inputValues.capMulTier1,
                        config.inputValues.capMulTier2,
                        config.inputValues.capMulTier3,
                        config.inputValues.capMulTier4,
                        config.inputValues.capMulTier5,
                        config.inputValues.capMulTier6,
                        config.inputValues.capMulTier7,
                        config.inputValues.capMulTier8
                    ],
                    tierActivation: [
                        config.inputValues.capMulActTier1,
                        config.inputValues.capMulActTier2,
                        config.inputValues.capMulActTier3,
                        config.inputValues.capMulActTier4,
                        config.inputValues.capMulActTier5,
                        config.inputValues.capMulActTier6,
                        config.inputValues.capMulActTier7,
                        config.inputValues.capMulActTier8
                    ]
                });
            }
            else {
                _sale_.applyWalletCap(1, {
                    maxWalletCap: config.inputValues.maxWalletCap,
                    tierMultiplierMode: true,
                    tierAddress: config.inputValues.tierCapMulAddress,
                    tierMultiplier: [
                        config.inputValues.capMulTier1,
                        config.inputValues.capMulTier2,
                        config.inputValues.capMulTier3,
                        config.inputValues.capMulTier4,
                        config.inputValues.capMulTier5,
                        config.inputValues.capMulTier6,
                        config.inputValues.capMulTier7,
                        config.inputValues.capMulTier8
                    ]
                });
            }
        }
        else {
            _sale_.applyWalletCap(1, { maxWalletCap: config.inputValues.maxWalletCap });
        }
    }
    //if only Min Cap Per Wallet is enabled
    if (!config.maxCapMode && config.minCapMode) {
        _sale_.applyWalletCap(0, { minWalletCap: config.inputValues.minWalletCap });
    }
    return _sale_;
}
export const saleDeploy = async (deployer, deployerAddress, config, ...args) => {
    const newSale = Sale.deploy(deployer, {
        canStartStateConfig: config.creatorControlMode
            ? new SaleDurationInTimestamp(config.inputValues.startTimestamp).applyOwnership(deployerAddress)
            : new SaleDurationInTimestamp(config.inputValues.startTimestamp),
        canEndStateConfig: canEndConfig(config, deployerAddress),
        calculatePriceStateConfig: calculatePriceConfig(config),
        recipient: config.inputValues.recipient,
        reserve: config.inputValues.reserve,
        saleTimeout: 10,
        cooldownDuration: parseInt(config.inputValues.cooldownDuration),
        minimumRaise: parseUnits(config.inputValues.minimumRaise.toString(), config.inputValues.reserveErc20?.erc20decimals),
        dustSize: 0,
    }, {
        erc20Config: {
            name: config.inputValues.name,
            symbol: config.inputValues.symbol,
            distributor: ethers.constants.AddressZero,
            initialSupply: parseUnits(config.inputValues.initialSupply.toString()),
        },
        tier: config.inputValues.tier,
        minimumTier: config.inputValues.minimumStatus,
        distributionEndForwardingAddress: config.inputValues.distributionEndForwardingAddress,
    });
    return newSale;
};
